<template>
  <div>
    <b-card-code
        title="Szerkesztés"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                  label="Név"
              >
                <validation-provider
                    #default="{ errors }"
                    name="cím"
                    rules="required"
                    vid="title"
                >
                  <b-form-input
                      v-model="formData.title"
                      :state="errors.length > 0 ? false:null"
                      placeholder="cím"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  label="Címke"
              >
                <validation-provider
                    #default="{ errors }"
                    name="címke"
                    vid="tags"
                >
                  <v-select
                      v-model="formData.tags"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      multiple
                      taggable
                      push-tags
                      :options="tagOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <validation-provider
                  #default="{ errors }"
                  name="mező"
                  vid="is_public"
              >
                <b-form-checkbox
                    v-model="formData.is_public"
                    :state="errors.length > 0 ? false:null"
                >
                  Nyilvános?
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                  #default="{ errors }"
                  name="mező"
                  vid="is_approved"
              >
                <b-form-checkbox
                    v-model="formData.is_approved"
                    :state="errors.length > 0 ? false:null"
                >
                  Publikált?
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                  #default="{ errors }"
                  name="mező"
                  vid="show_in_home_page"
              >
                <b-form-checkbox
                    v-model="formData.show_in_home_page"
                    :state="errors.length > 0 ? false:null"
                >
                  Megjelenik a főoldalon?
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="secondary"
                  type="submit"
                  class="float-left"
                  @click.prevent="downloadGallery"
              >
                Galéria letöltése
              </b-button>
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                Mentés
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <b-overlay
        :show="tabsLoading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code>
        <tabs
          ref="tabs"
          @refresh-tabs-data="refreshTabsData"
          :entity-id="parseInt(this.$route.params.id)"
        />
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BLink, BOverlay,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import tabs from '@/views/pages/_components/_tabs/_gallery/update.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BLink,
    BOverlay,
    vSelect,
    tabs,
    cancelAndGOTOPreviousPage,
  },
  data() {
    return {
      role: 'gallery',
      tabsLoading: false,
      formData: {
        id: null,
        title: '',
        is_public: false,
        is_approved: false,
        show_in_home_page: false,
        tags: [],
      },
      tagOptions: [],
      tabsProperties: {
        images: [],
      },
      required,
    }
  },
  created() {
    this.$store.dispatch('fetchAllTagsForBootstrap').then(response => {
      this.tagOptions = response
    })

    this.initEntity()
  },
  methods: {
    initEntity(onlyTabs = false) {
      this.$store.dispatch('fetchGallery', this.$route.params.id).then(response => {
        this.processEntity(response.data.entity, onlyTabs)
      })
    },
    processEntity(gallery, onlyTabs = false) {
      this.tabsLoading = true;

      if (!onlyTabs) {
        this.formData = gallery

        let selectedTags = []

        // set tags
        this.$store.dispatch('fetchAllTagsForBootstrap').then(response => {
          this.tagOptions = response

          if (this.tagOptions.length && gallery.tags && gallery.tags.length) {
            Object.keys(gallery.tags).forEach(tkey => {
              Object.keys(this.tagOptions).forEach(tokey => {
                if (gallery.tags[tkey].id === this.tagOptions[tokey].value) {
                  selectedTags.push(this.tagOptions[tokey])
                }
              })
            })
          }

          this.formData.tags = selectedTags
        })
      }

      this.tabsLoading = false;
    },
    refreshTabsData() {
      this.$refs.tabs.refreshImages()
      this.$refs.tabs.refreshLog()
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let fd = JSON.parse(JSON.stringify(this.formData))

          this.$store.dispatch('updateGallery', fd).then(async response => {
            this.processEntity(response.data.entity)
            this.$helpers.showSuccessToast()
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
    downloadGallery(){
      this.$store.dispatch('downloadGallery', {id: this.formData.id}).then(response => {

        let filename = this.formData.slug;

        const blobUrl = URL.createObjectURL(response.data)

        const link = document.createElement('a')

        link.href = blobUrl
        link.download = filename

        document.body.appendChild(link)

        link.dispatchEvent(
            new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              view: window,
            }),
        )

        document.body.removeChild(link)
      }).catch(error => {
        if (error.response.status === 422) {
          this.$refs.simpleRules.setErrors(error.response.data.errors)
        }else if (error.response.status === 400) {
          this.$helpers.showErrorToast('Üres galériát nem lehet letölteni.')
        } else {
          this.$helpers.showErrorToast(error.toString())
        }
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
